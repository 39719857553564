export const themeMui = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#D5D900'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#D5D900',
      dark: '#0C1A2E'
    },
    secondary: {
      main: '#FFF',
      dark: '#D5D900',
      lowTariff: '#1BBC9B',
      highTariff: '#F53920',
      stepColor: '#C6C8D2',
      stepSubtitleColor: '#959BA2',
      blockBackgroundLight: '#0C1A2E',
      darkBg: '#0A1627',
      gray: '#7C828B'
    },
    text: {
      primary: '#fff',
      secondary: '#000'
    }
  },
  typography: {
    h2: {
      fontSize: 20,
      fontWeight: 600
    },
    h3: {
      fontSize: 18,
      fontWeight: 600
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Texta, sans-serif'].join(',')
  }
};
